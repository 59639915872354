import Vue from 'vue';
import Vuex from 'vuex';
import { apiMethods } from 'api/graphql/cloud/methods';
import hash from 'object-hash';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    isServerVersionGreater: false,

    methods: {},
  },

  mutations: {
    setIsServerVersionGreater(state, value) {
      state.isServerVersionGreater = value;
    },

    setMethods(state, { hash, methodsPromise }) {
      state.methods = {
        ...state.methods,
        [hash]: methodsPromise,
      };
    },
    resetMethods(state) {
      state.methods = {};
    },
  },

  actions: {
    initMethods({ commit }, variables) {
      const variablesHash = hash(variables);
      const methodsPromise = apiMethods.getMethods(variables);
      commit('setMethods', { hash: variablesHash, methodsPromise });
    },
  },
});

export default store;
